import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {CssBaseline} from '@mui/material';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';
import FavoritesProvider from '@/components/FavoritesProvider/index.js';
import {router} from '@/router.js';

const queryClient = new QueryClient();
const container = document.getElementById('root');

if (!container) {
    throw new Error('Root element missing');
}

const root = createRoot(container);
root.render((
    <StrictMode>
        <CssBaseline/>
        <QueryClientProvider client={queryClient}>
            <FavoritesProvider>
                <RouterProvider router={router}/>
            </FavoritesProvider>
        </QueryClientProvider>
    </StrictMode>
));
