import {Box} from '@mui/material';
import type {ReactNode} from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useResizeDetector} from 'react-resize-detector';

const loadImage = async (url : string) : Promise<HTMLImageElement> => {
    const image = new Image();
    image.src = url;

    return new Promise((resolve, reject) => {
        image.onload = () => {
            resolve(image);
        };

        image.onerror = () => {
            reject(new Error(`Failed to load "${url}"`));
        };
    });
};

type LayoutImage = {
    x : number;
    y : number;
    width : number;
    height : number;
    aspectRatio : number;
    src : string;
};

type Props = {
    imageUrls : string[];
    onClick : (index : number) => void;
    maxRowHeight : number;
    spacing : number;
};

const ImageGrid = ({imageUrls, onClick, maxRowHeight, spacing} : Props) : ReactNode => {
    const [images, setImages] = useState<HTMLImageElement[] | null>(null);
    const {width, ref} = useResizeDetector({handleWidth: true, handleHeight: false});

    useEffect(() => {
        setImages(null);

        Promise.all(imageUrls.map(loadImage))
            .then(setImages)
            .catch(error => {
                console.error(error);
            });
    }, [imageUrls]);

    const [layout, height] = useMemo(() => {
        if (!images || !width) {
            return [null, null];
        }

        const layout : LayoutImage[] = [];
        let row : LayoutImage[] = [];
        let rowAspectRatio = 0;
        let availableWidth = width;
        let y = 0;
        let height = 0;

        const flushRow = (rowHeight : number, isPartialRow : boolean) => {
            const lastImage = row[row.length - 1];
            let x = 0;

            for (const image of row) {
                const actualWidth = rowHeight * image.aspectRatio;

                image.x = Math.round(x);
                image.width = Math.round(actualWidth);
                image.height = rowHeight;
                layout.push(image);

                if (!isPartialRow && image === lastImage && image.x + image.width !== width) {
                    image.width = width - x;
                }

                x += actualWidth + spacing;
            }

            return rowHeight + spacing;
        };

        for (const image of images) {
            const aspectRatio = image.width / image.height;
            rowAspectRatio += aspectRatio;

            const layoutImage = {
                x: 0,
                y,
                width: 0,
                height: 0,
                aspectRatio,
                src: image.src,
            };
            row.push(layoutImage);

            if (row.length > 1) {
                availableWidth -= spacing;
            }

            const rowHeight = Math.round(availableWidth / rowAspectRatio);

            if (rowHeight < maxRowHeight) {
                height += flushRow(rowHeight, false);
                row = [];
                rowAspectRatio = 0;
                availableWidth = width;
                y += rowHeight + spacing;
            }
        }

        if (row.length > 0) {
            height += flushRow(maxRowHeight, true);
        }

        return [layout, height];
    }, [images, width, maxRowHeight, spacing]);

    return (
        <Box
            ref={ref}
            sx={{
                position: 'relative',
                height: height ?? 0,
                overflow: 'hidden',
            }}
        >
            {layout && layout.map((image, index) => (
                <Box
                    key={image.src}
                    component="img"
                    alt=""
                    width={image.width}
                    height={image.height}
                    src={image.src}
                    sx={{
                        position: 'absolute',
                        left: image.x,
                        top: image.y,
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        onClick(index);
                    }}
                />
            ))}
        </Box>
    );
};

export default ImageGrid;
