import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import {Backdrop, Box, IconButton, Link, Toolbar, Typography, useMediaQuery, useTheme} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {useCallback, useEffect, useMemo, useState} from 'react';
import type {ReactNode} from 'react';
import Markdown from 'react-markdown';
import {useNavigate, useParams} from 'react-router-dom';
import FavoriteButton from '@/components/FavoriteButton/index.js';
import LinkButton from '@/components/LinkButton/index.js';
import {useGalleryContext} from '@/pages/Gallery/Gallery.js';
import ImageGrid from '@/pages/Gallery/ImageGrid.js';
import NotFound from '@/pages/NotFound.js';

type Params = {
    artworkId : string;
};

const Details = () : ReactNode => {
    const params = useParams<Params>();

    if (!params.artworkId) {
        throw new Error('Artwork ID missing');
    }

    const {galleryId, artworks, showOnlyFavorites, resolveGalleryUrl} = useGalleryContext();
    const [imageIndex, setImageIndex] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const narrowView = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setImageIndex(0);
    }, [params.artworkId]);

    const artworkIndex = artworks.findIndex(artwork => artwork.id === params.artworkId);

    const navigateGallery = useCallback((delta : number) => {
        const newIndex = (artworkIndex + delta + artworks.length) % artworks.length;
        const artwork = artworks[newIndex];

        navigate(`/${galleryId}/details/${artwork.id}`);
    }, [artworkIndex, artworks, navigate]);

    useEffect(() => {
        const handleKeyPress = (event : KeyboardEvent) => {
            if (event.key === 'Escape') {
                if (fullscreen) {
                    setFullscreen(false);
                    return;
                }

                navigate(`/${galleryId}`);
                return;
            }

            if (event.key === 'ArrowLeft') {
                navigateGallery(-1);
                return;
            }

            if (event.key === 'ArrowRight') {
                navigateGallery(1);
                return;
            }
        };

        document.addEventListener('keyup', handleKeyPress);

        return () => {
            document.removeEventListener('keyup', handleKeyPress);
        };
    }, [navigate, navigateGallery, galleryId, fullscreen]);

    const handleFavoriteChange = useCallback((isFavorite : boolean) => {
        if (!showOnlyFavorites || isFavorite) {
            return;
        }

        if (artworks.length === 1) {
            // This was the last favorite artwork, go back to overview.
            navigate(`/${galleryId}`);
            return;
        }

        navigateGallery(artworkIndex > 0 ? -1 : 1);
    }, [navigate, galleryId, artworks, artworkIndex, showOnlyFavorites, navigateGallery]);

    const imageUrls = useMemo(() => {
        if (!(artworkIndex in artworks)) {
            return [];
        }

        const artwork = artworks[artworkIndex];
        return artwork.imagePaths.map(resolveGalleryUrl);
    }, [artworks, artworkIndex, resolveGalleryUrl]);

    if (artworkIndex < 0) {
        return <NotFound/>;
    }

    const artwork = artworks[artworkIndex];

    return (
        <Box sx={{px: 4}}>
            <Grid container spacing={4}>
                <Grid xs={12} md={6}>
                    <Grid
                        sx={{
                            display: 'block',
                            overflow: 'hidden',
                        }}
                    >
                        {imageUrls.length > 0 && (
                            <Box
                                component="img"
                                src={imageUrls[imageIndex]}
                                alt=""
                                sx={theme => ({
                                    display: 'block',
                                    maxWidth: '100%',
                                    maxHeight: narrowView
                                        ? 600
                                        : `calc(100vh - 65px - ${theme.spacing(4 * 2)} - 60px)`,
                                    ml: 'auto',
                                    mr: narrowView ? 'auto' : undefined,
                                    cursor: 'zoom-in',
                                })}
                                onClick={() => {
                                    setFullscreen(true);
                                }}
                            />
                        )}
                        <Backdrop
                            open={fullscreen}
                            sx={theme => ({
                                zIndex: theme.zIndex.appBar + 1,
                                cursor: 'zoom-out',
                            })}
                            onClick={() => {
                                setFullscreen(false);
                            }}
                        >
                            <Box
                                component="img"
                                src={imageUrls[imageIndex]}
                                alt=""
                                sx={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                }}
                            />
                        </Backdrop>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}>
                    <Toolbar disableGutters>
                        <IconButton
                            onClick={() => {
                                navigateGallery(-1);
                            }}
                            edge="start"
                        >
                            <ArrowBackIosIcon sx={{ml: '4px', mr: '-4px'}}/>
                        </IconButton>
                        <Typography>{artworkIndex + 1} / {artworks.length}</Typography>
                        <IconButton
                            onClick={() => {
                                navigateGallery(1);
                            }}
                        >
                            <ArrowForwardIosIcon sx={{ml: '1px', mr: '-1px'}}/>
                        </IconButton>

                        <Box sx={theme => ({width: theme.spacing(8)})}/>

                        {artwork.link && (
                            <LinkButton {...artwork.link}/>
                        )}

                        <FavoriteButton
                            galleryId={galleryId}
                            artworkId={artwork.id}
                            onChange={handleFavoriteChange}
                        />
                        <IconButton
                            onClick={() => {
                                navigate(`/${galleryId}`);
                            }}
                            edge="end"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>

                    {imageUrls.length > 1 && (
                        <ImageGrid
                            imageUrls={imageUrls}
                            onClick={setImageIndex}
                            maxRowHeight={100}
                            spacing={16}
                        />
                    )}

                    {artwork.detailDescription && (
                        <Markdown
                            skipHtml
                            allowedElements={[
                                'br',
                                'p',
                                'em',
                                'strong',
                                'del',
                                'a',
                                'ul',
                                'li',
                            ]}
                            components={{
                                p: props => <Typography paragraph {...props}/>,
                                a: props => <Link {...props} color="secondary" target="_blank" rel="noreferrer"/>,
                            }}
                        >
                            {artwork.detailDescription}
                        </Markdown>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Details;
