import type {SxProps} from '@mui/material';
import {Box, Container} from '@mui/material';
import type {ReactNode} from 'react';
import ReactPlayer from 'react-player';
import {useResizeDetector} from 'react-resize-detector';
import Carousel from '@/pages/Gallery/Carousel.js';
import type {ResolveGalleryUrl} from '@/pages/Gallery/Gallery.js';
import type {MediaBox as MediaBoxSettings} from '@/queries/gallery.js';

type Props = {
    resolveGalleryUrl : ResolveGalleryUrl;
    mediaBox : MediaBoxSettings;
    sx ?: SxProps;
};

const MediaBox = ({resolveGalleryUrl, mediaBox, sx} : Props) : ReactNode => {
    let content : ReactNode;
    const {width, ref} = useResizeDetector({handleWidth: true, handleHeight: false});

    if (mediaBox.type === 'video') {
        if (!width) {
            return <div ref={ref}/>;
        }

        let videoWidth = width;
        let videoHeight = width / 16 * 9;

        if (!mediaBox.fullWidth && videoHeight > 600) {
            videoHeight = 600;
            videoWidth = 600 / 9 * 16;
        }

        content = (
            <ReactPlayer
                url={mediaBox.url}
                playing={mediaBox.autoplay}
                loop
                muted={mediaBox.autoplay}
                volume={1}
                controls={!mediaBox.autoplay}
                width={videoWidth}
                height={videoHeight}
            />
        );
    } else if (mediaBox.type === 'carousel') {
        const carousel = (
            <Carousel
                imagePaths={mediaBox.paths}
                resolveGalleryUrl={resolveGalleryUrl}
                delay={mediaBox.delay}
                instant={mediaBox.instant}
            />
        );

        if (mediaBox.fullWidth) {
            content = carousel;
        } else {
            content = (
                <Container>{carousel}</Container>
            );
        }
    } else {
        content = (
            <Box
                component="img"
                src={resolveGalleryUrl(mediaBox.path)}
                alt=""
                sx={{
                    display: 'block',
                    width: mediaBox.fullWidth ? '100%' : undefined,
                    maxWidth: !mediaBox.fullWidth ? '100%' : undefined,
                    maxHeight: !mediaBox.fullWidth ? 600 : undefined,
                }}
            />
        );
    }

    return (
        <div ref={ref}>
            <Box
                sx={mediaBox.fullWidth
                    ? {
                        ...sx,
                        position: 'relative',
                        height: mediaBox.type === 'carousel' ? undefined : 400,
                        overflow: 'hidden',
                    }
                    : {
                        ...sx,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }
                }
            >
                <div>
                    {content}
                </div>
            </Box>
        </div>
    );
};

export default MediaBox;
