import {Box} from '@mui/material';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import type {ReactNode} from 'react';
import type {ResolveGalleryUrl} from '@/pages/Gallery/Gallery.js';

type Props = {
    imagePaths : string[];
    resolveGalleryUrl : ResolveGalleryUrl;
    delay : number;
    instant : boolean;
};

const Carousel = ({imagePaths, resolveGalleryUrl, delay, instant} : Props) : ReactNode => {
    const [emblaRef] = useEmblaCarousel(
        {
            loop: true,
            watchDrag: false,
        },
        [
            Autoplay({delay, jump: instant}),
        ],
    );

    return (
        <Box
            sx={{
                overflow: 'hidden',
            }}
            ref={emblaRef}
        >
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                {imagePaths.map(imagePath => (
                    <Box
                        key={imagePath}
                        sx={{
                            flex: '0 0 100%',
                            minWidth: 0,
                        }}
                    >
                        <Box
                            component="img"
                            src={resolveGalleryUrl(imagePath)}
                            alt=""
                            sx={{
                                display: 'block',
                                margin: '0 auto',
                                maxWidth: '100%',
                                maxHeight: 600,
                            }}
                        />
                    </Box>
                ))}
            </Box>
        </Box>

    );
};

export default Carousel;
