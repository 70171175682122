import {useEffect} from 'react';

const usePageTitle = (title : string | null) : void => {
    useEffect(() => {
        let titleElement = document.head.querySelector<HTMLTitleElement>('title');

        if (!titleElement) {
            titleElement = document.createElement('title');
            document.head.appendChild(titleElement);
        }

        titleElement.innerText = title ?? 'Viewing Room';
    }, [title]);
};

export default usePageTitle;
