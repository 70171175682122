import type {
    ThemeOptions} from '@mui/material';
import {
    AppBar,
    Box,
    Button,
    Container,
    createTheme, CssBaseline, Link,
    ThemeProvider,
    Toolbar,
    Tooltip, Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import merge from 'deepmerge';
import type {ReactNode} from 'react';
import {useMemo} from 'react';
import {Outlet} from 'react-router-dom';
import {useFavoritesContext} from '@/components/FavoritesProvider/FavoritesProvider.js';
import useFavicon from '@/hooks/useFavicon.js';
import useFonts from '@/hooks/useFonts.js';
import usePageTitle from '@/hooks/usePageTitle.js';
import {useGalleryContext} from '@/pages/Gallery/Gallery.js';
import type {Gallery} from '@/queries/gallery.js';

declare module '@mui/material/styles' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Theme {
        custom : {
            favorite : string;
            linkButton : {
                textColor ?: string;
                borderColor ?: string;
                backgroundColor ?: string;
            };
        };
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface ThemeOptions {
        custom ?: {
            favorite ?: string;
            linkButton ?: {
                textColor ?: string;
                borderColor ?: string;
                backgroundColor ?: string;
            };
        };
    }
}

const defaultThemeOptions : ThemeOptions = {
    palette: {
        primary: {
            main: '#fff',
            contrastText: '#000',
        },
    },
    custom: {
        favorite: '#ef8B81',
        linkButton: {},
    },
};

type ArtworkWithEmailDescription = Gallery['artworks'][number] & {
    emailDescription : string;
};

const Scaffold = () : ReactNode => {
    const {galleryId, gallery, baseUrl, resolveGalleryUrl} = useGalleryContext();
    const {favorites} = useFavoritesContext(galleryId);
    useFavicon(gallery.meta?.faviconPath ? resolveGalleryUrl(gallery.meta.faviconPath) : null);
    usePageTitle(gallery.meta?.title ?? null);
    useFonts(gallery.fonts, baseUrl);

    const theme = useMemo(() => createTheme(merge(defaultThemeOptions, gallery.theme ?? {})), [gallery]);

    const openEmail = () => {
        if (!gallery.email) {
            return;
        }

        const artworks = gallery.artworks
            .filter(artwork => favorites.includes(artwork.id))
            .filter((artwork) : artwork is ArtworkWithEmailDescription => artwork.emailDescription !== undefined)
            .map(artwork => `- ${artwork.emailDescription}`)
            .join('\n');

        const url = new URL(`mailto:${gallery.email.recipientEmailAddress}`);
        url.searchParams.set('subject', gallery.email.subject);
        url.searchParams.set('body', gallery.email.template.replace(/{{artworks}}/g, () => artworks));

        window.location.href = url.toString().replace(/\+/g, '%20');
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <AppBar
                    position="sticky"
                    variant="outlined"
                    elevation={0}
                    sx={{
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderTop: 'none',
                    }}
                    enableColorOnDark
                >
                    <Toolbar>
                        <Box
                            component="img"
                            onClick={() => {
                                if (gallery.appBar.logo.linkUrl) {
                                    window.open(gallery.appBar.logo.linkUrl);
                                }
                            }}
                            src={resolveGalleryUrl(gallery.appBar.logo.path)}
                            alt=""
                            height={48}
                            sx={gallery.appBar.logo.linkUrl ? {cursor: 'pointer'} : undefined}
                        />

                        <Box sx={{flexGrow: 1}}/>

                        {gallery.email && (
                            <Tooltip title="Use ❤ to include artworks in your inquiry">
                                <Button onClick={openEmail} color="inherit">Send inquiry</Button>
                            </Tooltip>
                        )}
                    </Toolbar>
                </AppBar>

                <Box component="main" sx={{py: 4, flexGrow: 1}}>
                    <Outlet/>
                </Box>

                {gallery.footer && (
                    <Container maxWidth="lg" sx={{pt: 4, pb: 1}}>
                        <Grid container spacing={4} sx={{my: 0}}>
                            <Grid xs={true} sx={{py: 0, display: {xs: 'none', sm: 'block'}}}/>

                            {gallery.footer.copyright && (
                                <Grid sx={{py: 0}}>
                                    <Typography variant="body2" component="div" color="textSecondary">
                                        {gallery.footer.copyright}
                                    </Typography>
                                </Grid>
                            )}

                            {gallery.footer.branding && (
                                <Grid sx={{py: 0}}>
                                    <Typography variant="body2" component="div" color="textSecondary">
                                        <Link
                                            href={gallery.footer.branding.url}
                                            target="_blank"
                                            color="inherit"
                                            rel="noreferrer"
                                            sx={{textDecoration: 'none'}}
                                        >
                                            {gallery.footer.branding.label}
                                        </Link>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Container>
                )}
            </Box>
        </ThemeProvider>
    );
};

export default Scaffold;
