import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import {z} from 'zod';

const schemeRegex = /^[a-z][a-z0-9+\-.]*:/i;

const urlSchema = z.preprocess(value => {
    if (typeof value !== 'string' || schemeRegex.test(value)) {
        return value;
    }

    return `https://${value}`;
}, z.string().url());

const optionalStringSchema = z.preprocess(value => {
    if (value === '') {
        return undefined;
    }

    return value;
}, z.string().trim().min(1).optional());

const colorSchema = z.string()
    .regex(/^(#[0-9a-f]{3}|#(?:[0-9a-f]{2}){2,4}|(rgb|hsl)a?\((-?\d+%?[,\s]+){2,3}\s*[\d.]+%?\))$/);

const v1GallerySchema = z.object({
    logoImage: z.string().min(1),
    homepageUrl: urlSchema.optional(),
    header: z.object({
        title: z.string().min(1),
        subtitle: optionalStringSchema,
    }).optional(),
    teaserImage: optionalStringSchema,
    teaser: z.object({
        image: optionalStringSchema,
        video: optionalStringSchema,
        fullWidth: z.boolean().optional(),
    }).optional(),
    description: optionalStringSchema,
    email: z.object({
        recipientEmailAddress: z.string().email(),
        subject: z.string().min(1),
        template: z.string().min(1),
    }).optional(),
    artworks: z.array(z.object({
        id: z.string().min(1),
        thumbnail: z.string().min(1),
        images: z.array(z.string().min(1)).min(1),
        listDescription: z.string().min(1),
        detailDescription: z.string().min(1),
        emailDescription: optionalStringSchema,
        link: z.object({
            label: z.string().min(1),
            url: urlSchema,
        }).optional(),
    }))
        .min(1)
        .refine(
            artworks => new Set([...artworks.map(artwork => artwork.id)]).size === artworks.length,
            'Artwork IDs must be unique'
        ),
    copyright: optionalStringSchema,
    branding: z.object({
        label: z.string().min(1),
        url: urlSchema,
    }).optional(),
    theme: z.object({
        palette: z.object({
            type: z.enum(['light', 'dark']).optional(),
            primary: z.object({
                main: colorSchema,
                contrastText: colorSchema.optional(),
            }).optional(),
            secondary: z.object({
                main: colorSchema,
                contrastText: colorSchema.optional(),
            }).optional(),
            divider: colorSchema.optional(),
            text: z.object({
                primary: colorSchema,
                secondary: colorSchema.optional(),
                disabled: colorSchema.optional(),
            }).optional(),
        }).optional(),
        custom: z.object({
            favorite: colorSchema.optional(),
            linkButton: z.object({
                textColor: colorSchema.optional(),
                borderColor: colorSchema.optional(),
                backgroundColor: colorSchema.optional(),
            }).optional(),
        }).optional(),
    }).optional(),
}).transform(gallery => {
    if (gallery.teaserImage && !gallery.teaser) {
        gallery.teaser = {image: gallery.teaserImage};
        console.warn('The "teaserImage" property is deprecated, use "teaser" instead.');
    }

    return gallery;
}).transform((gallery) : Gallery => ({
    version: 2,
    appBar: {
        logo: {
            path: gallery.logoImage,
            linkUrl: gallery.homepageUrl,
        },
    },
    footer: gallery.copyright || gallery.branding
        ? {
            copyright: gallery.copyright,
            branding: gallery.branding,
        }
        : undefined,
    email: gallery.email,
    overview: {
        header: gallery.header,
        start: {
            mediaBox: gallery.teaser?.image
                ? {
                    type: 'image',
                    path: gallery.teaser.image,
                    fullWidth: gallery.teaser.fullWidth,
                }
                : gallery.teaser?.video
                    ? {
                        type: 'video',
                        url: gallery.teaser.video,
                        fullWidth: gallery.teaser.fullWidth,
                        autoplay: true,
                    }
                    : undefined,
            text: gallery.description,
        },
    },
    artworks: gallery.artworks.map(artwork => ({
        ...artwork,
        thumbnailPath: artwork.thumbnail,
        imagePaths: artwork.images,
    })),
    theme: gallery.theme
        ? {
            ...gallery.theme,
            palette: {
                ...gallery.theme.palette,
                mode: gallery.theme.palette?.type,
            },
        }
        : undefined,
}));

const mediaBoxSchema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal('image'),
        path: z.string().min(1),
    }),
    z.object({
        type: z.literal('carousel'),
        paths: z.array(z.string().min(1)).min(2),
        delay: z.number().int().positive().default(4000),
        instant: z.boolean().default(false),
    }),
    z.object({
        type: z.literal('video'),
        url: z.string().url(),
        autoplay: z.boolean().default(true),
    }),
]).and(z.object({
    fullWidth: z.boolean().optional(),
}));

export type MediaBox = z.infer<typeof mediaBoxSchema>;

const v2GallerySchema = z.object({
    version: z.literal(2),
    meta: z.object({
        title: z.string().trim().min(1).optional(),
        faviconPath: z.string().trim().min(1).optional(),
    }).optional(),
    googleAnalytics: z.object({
        measurementId: z.string().min(1),
    }).optional(),
    appBar: z.object({
        logo: z.object({
            path: z.string().trim().min(1),
            linkUrl: z.string().url().optional(),
        }),
    }),
    footer: z.object({
        copyright: z.string().trim().min(1).optional(),
        branding: z.object({
            label: z.string().trim().min(1),
            url: urlSchema,
        }).optional(),
    })
        .transform(footer => footer.copyright || footer.branding ? footer : undefined)
        .optional(),
    email: z.object({
        recipientEmailAddress: z.string().email(),
        subject: z.string().trim().min(1),
        template: z.string().trim().min(1),
    }).optional(),
    overview: z.object({
        header: z.object({
            title: z.string().trim().min(1),
            subtitle: z.string().trim().min(1).optional(),
        }).optional(),
        start: z.object({
            mediaBox: mediaBoxSchema.optional(),
            text: z.string().trim().min(1).optional(),
        }).optional(),
        end: z.object({
            mediaBox: mediaBoxSchema.optional(),
            text: z.string().trim().min(1).optional(),
        }).optional(),
    }).optional(),
    artworks: z.array(z.object({
        id: z.string().trim().min(1),
        thumbnailPath: z.string().trim().min(1).optional(),
        imagePaths: z.array(z.string().trim().min(1)).default([]),
        listDescription: z.string().trim().min(1).optional(),
        detailDescription: z.string().trim().min(1).optional(),
        emailDescription: z.string().trim().min(1).optional(),
        link: z.object({
            label: z.string().trim().min(1),
            url: urlSchema,
        }).optional(),
    }))
        .min(1)
        .refine(
            artworks => new Set([...artworks.map(artwork => artwork.id)]).size === artworks.length,
            'Artwork IDs must be unique'
        ),
    theme: z.object({
        typography: z.object({
            fontFamily: z.string()
                .trim()
                .min(1)
                .transform(family => [`"${family}"`, 'Roboto', 'sans-serif'].join(','))
                .optional(),
        }).optional(),
        palette: z.object({
            mode: z.enum(['light', 'dark']).optional(),
            background: z.object({
                default: colorSchema.optional(),
            }).optional(),
            primary: z.object({
                main: colorSchema,
                contrastText: colorSchema.optional(),
            }).optional(),
            secondary: z.object({
                main: colorSchema,
                contrastText: colorSchema.optional(),
            }).optional(),
            divider: colorSchema.optional(),
            text: z.object({
                primary: colorSchema,
                secondary: colorSchema.optional(),
                disabled: colorSchema.optional(),
            }).optional(),
        }).optional(),
        custom: z.object({
            favorite: colorSchema.optional(),
            linkButton: z.object({
                textColor: colorSchema.optional(),
                borderColor: colorSchema.optional(),
                backgroundColor: colorSchema.optional(),
            }).optional(),
        }).optional(),
    }).optional(),
    fonts: z.array(z.object({
        family: z.string().trim().min(1),
        variantPaths: z.object({
            regular: z.string().trim().min(1),
            bold: z.string().trim().min(1).optional(),
            italic: z.string().trim().min(1).optional(),
            boldItalic: z.string().trim().min(1).optional(),
        }),
    })).optional(),
});

export type Gallery = Omit<z.infer<typeof v2GallerySchema>, 'teaserImage'>;

const selector = (raw : unknown) : Gallery => {
    if (raw && typeof raw === 'object' && 'version' in raw) {
        return v2GallerySchema.parse(raw);
    }

    const gallery = v1GallerySchema.parse(raw);
    console.warn('Gallery uses deprecated v1 schema. Migrate to v2.');
    return gallery;
};

export const useGalleryQuery = (baseUrl : string) : UseQueryResult<Gallery, Error> => {
    return useQuery(['gallery', baseUrl], async ({signal}) => {
        const response = await fetch(`${baseUrl}/gallery.json`, {
            signal,
        });

        if (!response.ok) {
            throw new Error(`Unable to fetch gallery metadata with baseurl "${baseUrl}"`);
        }

        return await response.json() as unknown;
    }, {
        retry: false,
        select: selector,
    });
};
