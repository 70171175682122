import {useEffect} from 'react';
import defaultPngIcon from '../../favicon.png';
import defaultSvgIcon from '../../favicon.svg';

const useFavicon = (url : string | null) : void => {
    useEffect(() => {
        const links = document.head.querySelectorAll<HTMLLinkElement>('link[rel~="icon"]');

        for (const link of links) {
            document.head.removeChild(link);
        }

        if (!url) {
            const svgLink = document.createElement('link');
            svgLink.rel = 'icon';
            svgLink.type = 'image/svg+xml';
            svgLink.href = defaultSvgIcon;
            document.head.appendChild(svgLink);

            const pngLink = document.createElement('link');
            pngLink.rel = 'icon';
            pngLink.type = 'image/png';
            pngLink.href = defaultPngIcon;
            document.head.appendChild(pngLink);

            return;
        }

        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = url;
        document.head.appendChild(link);
    }, [url]);
};

export default useFavicon;
