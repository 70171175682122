import {Container, Typography} from '@mui/material';
import type {ReactNode} from 'react';

const NotFound = () : ReactNode => {
    return (
        <Container sx={{py: 3}}>
            <Typography variant="h5">File not found</Typography>
            <Typography>
                The requested file could not be found.
            </Typography>
        </Container>
    );
};

export default NotFound;
