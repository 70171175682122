import type {ReactNode} from 'react';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import type {GoogleAnalytics} from '@/pages/Gallery/Gallery.js';

type Props = {
    googleAnalytics : GoogleAnalytics;
};

const PageViewTracker = ({googleAnalytics} : Props) : ReactNode => {
    const {pathname, search} = useLocation();

    useEffect(() => {
        googleAnalytics.sendPageView(`${pathname}${search}`, document.title);
    }, [googleAnalytics, pathname, search]);

    return null;
};

export default PageViewTracker;
