import {alpha, Button} from '@mui/material';
import type {ReactNode} from 'react';

type Props = {
    url : string;
    label : string;
};

const LinkButton = ({url, label} : Props) : ReactNode => {
    return (
        <Button
            sx={theme => ({
                textTransform: 'none',
                border: theme.custom.linkButton.borderColor
                    ? `1px solid ${theme.custom.linkButton.borderColor}`
                    : 'none',
                color: theme.custom.linkButton.textColor ?? 'inherit',
                backgroundColor: theme.custom.linkButton.backgroundColor,
                '&:hover': {
                    backgroundColor: theme.custom.linkButton.backgroundColor
                        ? alpha(
                            theme.custom.linkButton.backgroundColor,
                            1 - theme.palette.action.hoverOpacity,
                        )
                        : undefined,
                },
            })}
            onClick={event => {
                window.open(url);
                event.stopPropagation();
            }}
        >
            {label}
        </Button>
    );
};

export default LinkButton;
