import {Container} from '@mui/material';
import Typography from '@mui/material/Typography';
import type {ReactNode} from 'react';

const Error = () : ReactNode => {
    return (
        <Container sx={{py: 3}}>
            <Typography variant="h5">An error occurred</Typography>
            <Typography>
                An unknown error occurred.
            </Typography>
        </Container>
    );
};

export default Error;
