import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom';
import Error from '@/pages/Error.js';
import Gallery, {Details, Overview} from '@/pages/Gallery/index.js';
import Home from '@/pages/Home.js';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" errorElement={<Error/>}>
            <Route index element={<Home/>}/>
            <Route path=":galleryId" element={<Gallery/>}>
                <Route index element={<Overview/>}/>
                <Route path="details/:artworkId" element={<Details/>}/>
            </Route>
        </Route>
    )
);
