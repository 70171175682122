import {useEffect, useMemo} from 'react';
import type {Gallery} from '@/queries/gallery.js';

const loadFontFace = async (fontFace : FontFace) : Promise<void> => {
    const font = await fontFace.load();
    document.fonts.add(font);
    console.log(font);
};

const useFonts = (fonts : Gallery['fonts'], baseUrl : string) : void => {
    const fontFaces = useMemo(() => {
        const fontFaces : FontFace[] = [];

        if (!fonts) {
            return fontFaces;
        }

        for (const font of fonts) {
            fontFaces.push(new FontFace(
                font.family,
                `url(${baseUrl}/${font.variantPaths.regular})`,
                {weight: 'normal'}
            ));

            if (font.variantPaths.bold) {
                fontFaces.push(new FontFace(
                    font.family,
                    `url(${baseUrl}/${font.variantPaths.bold})`,
                    {weight: 'bold'}
                ));
            }

            if (font.variantPaths.italic) {
                fontFaces.push(new FontFace(
                    font.family,
                    `url(${baseUrl}/${font.variantPaths.italic})`,
                    {weight: 'normal', style: 'italic'}
                ));
            }

            if (font.variantPaths.boldItalic) {
                fontFaces.push(new FontFace(
                    font.family,
                    `url(${baseUrl}/${font.variantPaths.boldItalic})`,
                    {weight: 'bold', style: 'italic'}
                ));
            }
        }

        return fontFaces;
    }, [fonts]);

    useEffect(() => {
        fontFaces.forEach(fontFace => {
            void loadFontFace(fontFace);
        });

        return () => {
            fontFaces.forEach(fontFace => {
                document.fonts.delete(fontFace);
            });
        };
    }, [fontFaces]);
};

export default useFonts;
