import type {Dispatch, ReactNode} from 'react';
import {createContext, useCallback, useContext, useMemo, useReducer} from 'react';

type Favorites = Record<string, string[]>;

type LoadFavoriteAction = {
    type : 'loadFavorites';
    payload : {
        galleryId : string;
        artworkIds : string[];
    };
};

type AddFavoriteAction = {
    type : 'addFavorite';
    payload : {
        galleryId : string;
        artworkId : string;
    };
};

type RemoveFavoriteAction = {
    type : 'removeFavorite';
    payload : {
        galleryId : string;
        artworkId : string;
    };
};

type Action = LoadFavoriteAction | AddFavoriteAction | RemoveFavoriteAction;

const favoritesReducer = (state : Favorites, action : Action) : Favorites => {
    if (action.type === 'loadFavorites') {
        const {galleryId, artworkIds} = action.payload;
        const storedFavorites = localStorage.getItem(`favorites-${galleryId}`);

        if (!storedFavorites) {
            return state;
        }

        const newState = {
            ...state,
            [galleryId]: (JSON.parse(storedFavorites) as string[])
                .filter((artworkId : string) => artworkIds.includes(artworkId)),
        };

        localStorage.setItem(`favorites-${galleryId}`, JSON.stringify(newState[galleryId]));
        return newState;
    }

    const {galleryId, artworkId} = action.payload;
    let newState;

    switch (action.type) {
        case 'addFavorite':
            newState = {
                ...state,
                [galleryId]: !(galleryId in state) ? [artworkId] : [...state[galleryId], artworkId],
            };
            break;

        case 'removeFavorite':
            newState = !(galleryId in state)
                ? state
                : {
                    ...state,
                    [galleryId]: state[galleryId].filter(currentArtworkId => currentArtworkId !== artworkId),
                };
            break;
    }

    if (!(galleryId in newState) || newState[galleryId].length === 0) {
        localStorage.removeItem(`favorites-${galleryId}`);
    } else {
        localStorage.setItem(`favorites-${galleryId}`, JSON.stringify(newState[galleryId]));
    }

    return newState;
};

type FavoritesContextValue = {
    favorites : Favorites;
    dispatch : Dispatch<Action>;
};

const favoritesContext = createContext<FavoritesContextValue | null>(null);

type Props = {
    children ?: ReactNode;
};

const FavoritesProvider = ({children} : Props) : ReactNode => {
    const [favorites, dispatch] = useReducer(favoritesReducer, {});

    return (
        <favoritesContext.Provider value={{favorites, dispatch}}>
            {children}
        </favoritesContext.Provider>
    );
};

type FavoritesContext = {
    loadFavorites : (artworkIds : string[]) => void;
    addFavorite : (artworkId : string) => void;
    removeFavorite : (artworkId : string) => void;
    favorites : string[];
};

export const useFavoritesContext = (galleryId : string) : FavoritesContext => {
    const context = useContext(favoritesContext);

    if (!context) {
        throw new Error('FavoritesContext used outside provider');
    }

    const loadFavorites = useCallback((artworkIds : string[]) => {
        context.dispatch({
            type: 'loadFavorites',
            payload: {galleryId, artworkIds},
        });
    }, [context.dispatch, galleryId]);

    const addFavorite = useCallback((artworkId : string) => {
        context.dispatch({
            type: 'addFavorite',
            payload: {galleryId, artworkId},
        });
    }, [context.dispatch, galleryId]);

    const removeFavorite = useCallback((artworkId : string) => {
        context.dispatch({
            type: 'removeFavorite',
            payload: {galleryId, artworkId},
        });
    }, [context.dispatch, galleryId]);

    return useMemo(() => ({
        loadFavorites,
        addFavorite,
        removeFavorite,
        favorites: galleryId in context.favorites ? context.favorites[galleryId] : [],
    }), [galleryId, context.favorites, loadFavorites, addFavorite, removeFavorite]);
};

export default FavoritesProvider;
