import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import type {SxProps} from '@mui/material';
import {IconButton} from '@mui/material';
import type {MouseEvent, ReactNode} from 'react';
import {useFavoritesContext} from '@/components/FavoritesProvider/FavoritesProvider.js';

type Props = {
    galleryId : string;
    artworkId : string;
    onChange ?: (isFavorite : boolean) => void;
    edge ?: 'start' | 'end';
    sx ?: SxProps;
};

const FavoriteButton = ({galleryId, artworkId, onChange, edge, sx} : Props) : ReactNode => {
    const {favorites, addFavorite, removeFavorite} = useFavoritesContext(galleryId);
    const isFavorite = favorites.includes(artworkId);

    const toggleFavorite = (event : MouseEvent<HTMLElement>) => {
        if (onChange) {
            onChange(!isFavorite);
        }

        if (isFavorite) {
            removeFavorite(artworkId);
        } else {
            addFavorite(artworkId);
        }

        event.stopPropagation();
    };

    return (
        <IconButton
            onClick={toggleFavorite}
            edge={edge}
            sx={sx}
        >
            {isFavorite ? <FavoriteIcon sx={theme => ({color: theme.custom.favorite})}/> : <FavoriteBorderIcon/>}
        </IconButton>
    );
};

export default FavoriteButton;
