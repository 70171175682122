import type {SxProps} from '@mui/material';
import {Box, Container, FormControlLabel, Link, Switch, Toolbar, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {useMemo} from 'react';
import type {ReactNode} from 'react';
import Markdown from 'react-markdown';
import {useNavigate} from 'react-router-dom';
import FavoriteButton from '@/components/FavoriteButton/index.js';
import {useFavoritesContext} from '@/components/FavoritesProvider/FavoritesProvider.js';
import LinkButton from '@/components/LinkButton/index.js';
import {useGalleryContext} from '@/pages/Gallery/Gallery.js';
import MediaBox from '@/pages/Gallery/MediaBox.js';

type TextProps = {
    text : string;
    sx ?: SxProps;
};

const Text = ({text, sx} : TextProps) : ReactNode => (
    <Box
        sx={{
            ...sx,
            textAlign: 'justify',
            px: {lg: 10},
        }}
    >
        <Markdown
            skipHtml
            allowedElements={[
                'br',
                'p',
                'em',
                'strong',
                'del',
                'a',
            ]}
            unwrapDisallowed
            components={{
                p: props => <Typography paragraph {...props}/>,
                a: props => <Link {...props} color="secondary" target="_blank" rel="noreferrer"/>,
            }}
        >
            {text}
        </Markdown>
    </Box>
);

const Overview = () : ReactNode => {
    const {
        galleryId,
        gallery,
        artworks,
        showOnlyFavorites,
        setShowOnlyFavorites,
        resolveGalleryUrl,
    } = useGalleryContext();
    const {favorites} = useFavoritesContext(galleryId);
    const navigate = useNavigate();
    const {overview} = gallery;

    const [mediumItemSlots, largeItemSlots] = useMemo(() => [
        artworks.length < 2 ? 12 : 6,
        artworks.length < 2 ? 12 : (artworks.length < 3 ? 6 : 4),
    ], [artworks.length]);

    return (
        <>
            {overview?.header && (
                <Container maxWidth="lg" sx={{mb: 4}}>
                    <Typography variant="h4">{overview.header.title}</Typography>
                    {overview.header.subtitle && <Typography>{overview.header.subtitle}</Typography>}
                </Container>
            )}

            {overview?.start?.mediaBox && (
                <MediaBox resolveGalleryUrl={resolveGalleryUrl} mediaBox={overview.start.mediaBox} sx={{mb: 4}}/>
            )}

            <Container maxWidth="lg">
                {overview?.start?.text && <Text text={overview.start.text} sx={{mb: 4}}/>}

                <Toolbar disableGutters>
                    <FormControlLabel
                        disabled={favorites.length === 0}
                        control={(
                            <Switch
                                checked={showOnlyFavorites}
                                onChange={(event, checked) => {
                                    setShowOnlyFavorites(checked);
                                }}
                                color="default"
                            />
                        )}
                        label="Show only favorites"
                    />
                </Toolbar>

                <Grid container spacing={4}>
                    {artworks.map(artwork => (
                        <Grid
                            xs={12}
                            md={mediumItemSlots}
                            lg={largeItemSlots}
                            key={artwork.id}
                            sx={{cursor: 'pointer'}}
                            onClick={() => {
                                navigate(`/${galleryId}/details/${artwork.id}`);
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: 350,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {artwork.thumbnailPath && (
                                    <Box
                                        component="img"
                                        src={resolveGalleryUrl(artwork.thumbnailPath)}
                                        alt=""
                                        sx={{
                                            display: 'block',
                                            maxWidth: '100%',
                                            maxHeight: 350,
                                        }}
                                    />
                                )}
                            </Box>

                            {artwork.listDescription && (
                                <Typography component="div" sx={{mt: 2}}>
                                    <Markdown
                                        skipHtml
                                        allowedElements={[
                                            'br',
                                            'p',
                                            'em',
                                            'strong',
                                            'del',
                                        ]}
                                        unwrapDisallowed
                                        components={{
                                            p: props => <Typography paragraph {...props}/>,
                                        }}
                                    >
                                        {artwork.listDescription}
                                    </Markdown>
                                </Typography>
                            )}

                            <FavoriteButton galleryId={galleryId} artworkId={artwork.id} edge="start"/>

                            {artwork.link && (
                                <LinkButton {...artwork.link}/>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {overview?.end?.mediaBox && (
                <MediaBox resolveGalleryUrl={resolveGalleryUrl} mediaBox={overview.end.mediaBox} sx={{mt: 4}}/>
            )}

            {overview?.end?.text && (
                <Container maxWidth="lg">
                    <Text text={overview.end.text} sx={{mt: 4}}/>
                </Container>
            )}
        </>
    );
};

export default Overview;
