import {Box, CircularProgress, Container, Typography} from '@mui/material';
import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import type {ReactNode} from 'react';
import ReactGA from 'react-ga4';
import {useParams} from 'react-router-dom';
import Scaffold from './Scaffold.js';
import {useFavoritesContext} from '@/components/FavoritesProvider/FavoritesProvider.js';
import PageViewTracker from '@/pages/Gallery/PageViewTracker.js';
import {Gallery, useGalleryQuery} from '@/queries/gallery.js';

type Params = {
    galleryId : string;
};

export type ResolveGalleryUrl = (path : string) => string;

type GalleryContextValue = {
    galleryId : string;
    gallery : Gallery;
    artworks : Gallery['artworks'];
    baseUrl : string;
    resolveGalleryUrl : ResolveGalleryUrl;
    showOnlyFavorites : boolean;
    setShowOnlyFavorites : (value : boolean) => void;
};

const galleryContext = createContext<GalleryContextValue | null>(null);

export const useGalleryContext = () : GalleryContextValue => {
    const context = useContext(galleryContext);

    if (!context) {
        throw new Error('useGalleryContext used outside provider');
    }

    return context;
};

export type GoogleAnalytics = {
    sendPageView : (page : string, title : string) => void;
};

const useBaseUrl = (galleryId : string) : string => {
    if (window.location.hostname === import.meta.env.VITE_APP_ROOT_DOMAIN) {
        return `${import.meta.env.VITE_APP_DATA_URL}/${galleryId}`;
    }

    const clientId = window.location.host.split('.')[0];
    return `${import.meta.env.VITE_APP_DATA_URL}/${clientId}/${galleryId}`;
};

const Gallery = () : ReactNode => {
    const {galleryId} = useParams<Params>();

    if (!galleryId) {
        throw new Error('Gallery ID missing');
    }

    const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
    const baseUrl = useBaseUrl(galleryId);
    const galleryQuery = useGalleryQuery(baseUrl);
    const {favorites, loadFavorites} = useFavoritesContext(galleryId);

    useEffect(() => {
        if (favorites.length === 0 && showOnlyFavorites) {
            setShowOnlyFavorites(false);
        }
    }, [showOnlyFavorites, favorites]);

    useEffect(() => {
        if (galleryQuery.data) {
            loadFavorites(galleryQuery.data.artworks.map(artwork => artwork.id));
        }
    }, [loadFavorites, galleryQuery.data]);

    const googleAnalytics = useMemo(() : GoogleAnalytics => {
        ReactGA.reset();

        if (!galleryQuery.data?.googleAnalytics) {
            return {
                sendPageView: () => {
                    // Noop
                },
            };
        }

        ReactGA.initialize(galleryQuery.data.googleAnalytics.measurementId);

        return {
            sendPageView: (page : string, title : string) => {
                ReactGA.send({hitType: 'pageview', page, title});
            },
        };
    }, [galleryQuery.data]);

    const galleryContextValue = useMemo(() => {
        if (!galleryQuery.data) {
            return null;
        }

        return {
            galleryId,
            gallery: galleryQuery.data,
            artworks: showOnlyFavorites
                ? galleryQuery.data.artworks.filter(artwork => favorites.includes(artwork.id))
                : galleryQuery.data.artworks,
            resolveGalleryUrl: (path : string) => `${baseUrl}/${path}`,
            baseUrl,
            showOnlyFavorites,
            setShowOnlyFavorites,
        };
    }, [galleryId, galleryQuery.data, baseUrl, showOnlyFavorites, favorites]);

    if (galleryQuery.isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress size={80}/>
            </Box>
        );
    }

    if (galleryQuery.isError) {
        return (
            <Container sx={{py: 3}}>
                <Typography variant="h5">Notice</Typography>
                <Typography>
                    Viewing room no longer available.
                </Typography>
            </Container>
        );
    }

    return (
        <galleryContext.Provider value={galleryContextValue}>
            <PageViewTracker googleAnalytics={googleAnalytics}/>
            <Scaffold/>
        </galleryContext.Provider>
    );
};

export default Gallery;
