import type {ReactNode} from 'react';
import NotFound from './NotFound.js';

const Home = () : ReactNode => {
    if (import.meta.env.PROD) {
        window.location.href = 'https://www.primerarchives.com/';
        return null;
    }

    return <NotFound/>;
};

export default Home;
